import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Content, HTMLContent, Layout, SectionWrapper } from "../components";
import "../styles/_page.scss";

interface PrivacyConfig {
  pageTitle: string;
  title: string;
  description: string;
}
type Props = {
  data: PrivacyConfig;
  content?: React.ReactNode;
  contentComponent?: any;
  className?: string;
  helmet?: any;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const PrivacyTemplate: React.FC<Props> = ({
  data,
  contentComponent,
  content,
  helmet,
}: Props) => {
  const PostContent = contentComponent || Content;
  return (
    <SectionWrapper
      className="half-height container-width-l"
      title={data.title}
    >
      {helmet || ""}
      {data.description && (
        <h2
          className="section__header__container--title"
          style={{ margin: "2rem 0", textAlign: "center" }}
        >
          {data.description}
        </h2>
      )}
      <PostContent
        content={content}
        className="page__wrapper__content text-center"
      />
    </SectionWrapper>
  );
};

const PrivacyPage: React.FC<{ data: { markdownRemark: any } }> = ({ data }) => {
  const { markdownRemark: privacy } = data;
  return (
    <Layout>
      <PrivacyTemplate
        content={privacy.html}
        contentComponent={HTMLContent}
        data={privacy.frontmatter}
        helmet={
          <Helmet titleTemplate="REAL CVLTVRE | %s">
            <title>{`${privacy.frontmatter.pageTitle}`}</title>
            <meta
              property="og:title"
              content={`REAL CVLTVRE | ${privacy.frontmatter.pageTitle}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

export default PrivacyPage;

export const pageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageTitle
        title
        description
      }
    }
  }
`;
